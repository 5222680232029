import React from "react";
import Header from "../components/Header";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.css';
import UiPanel from "components/UiPanel";
import CareerProfile from "components/CareerProfile";
import Experience from "components/Experience";
import Education from "components/Education";
import Skills from "components/Skills";
import Interests from "components/Interests";
import Portfolio from "components/Portfolio";



function Home(){
    return (
        <Container>
            <Row >
                <Col  xs={12}
                      md={12}
                      lg={4}>
                    <Row>
                        <Col 
                            xs={{ span: 12, order:2}} 
                            lg={{ span: 12, order:2}} 
                        >
                            <UiPanel children={<Header />} />
                        </Col>
                        <Col 
                            xs={{ span: 12, order:3}} 
                            lg={{ span: 12, order:3}} 
                          >
                            <UiPanel children={<Education />} />
                        </Col>
                        <Col 
                            xs={{ span: 12, order:4}} 
                            lg={{ span: 12, order:4}} 
                        >
                            <UiPanel children={<Skills />} />
                        </Col>
                        <Col 
                            xs={{ span: 12, order:5}} 
                            lg={{ span: 12, order:5}} 
                        >
                            <UiPanel children={<Interests />} />
                        </Col>
                    </Row>

                </Col>

                <Col 
                    xs={12} 
                    md={12} 
                    lg={8}
                    >
                    <Row>
                        <Col 
                            xs={12}
                            sm={12} 
                            md={12} 
                            lg={12} 
                            >
                            <UiPanel children={<CareerProfile />} />
                        </Col>
                        <Col 
                            xs={{ span: 12, order:7}}
                            sm={{ span: 12, order:7}} 
                            md={{ span: 12, order:7}} 
                            lg={{ span: 12, order:7}} 
                            >
                            <UiPanel children={<Experience />} />
                        </Col>
                        
                            <Col 
                                xs={{ span: 12, order:8}}
                                sm={{ span: 12, order:8}} 
                                md={{ span: 12, order:8}} 
                                lg={{ span: 12, order:8}} 
                               >
                                <UiPanel children={<Portfolio />} />
                            </Col>

                    </Row>
                    
                </Col>
                
            </Row>
               
           
        </Container>
    )
}

export default Home;