import React from "react";
import { Col } from "react-bootstrap";
import '../css/ui-panel.css';
import '../css/header.css';

function CareerProfile() {

    return (
        <>
            <Col className="p-2" sm={12}>
                {/* <div className="ui-text-shadow header-text text-sm-begin text-md-end">About Me</div> */}
                <div className="text-center p-2l title">
                            “There are a million paths in this world, but any sage will 
                            tell you they can all be <br />reduced to one. Improve yourself.”
                            ― Will Wight, Unsouled
                        </div>
            </Col>

        </>
    )
}

export default CareerProfile;