import React from "react";
import { Col } from "react-bootstrap";
import '../css/ui-panel.css';
import '../css/header.css';

function Education() {

    return (
        <>
            <Col className=" text-md-begin p-2" sm={12} md={12}>
                <div className="ui-text-shadow header-text">Education</div>
                <div className="mt-2">
                    <div className="title">CISSP Course</div>
                    <div className="place">Online ISC<sup>2</sup> - 2021</div>
                </div>
                <div className="mt-2">
                    <div className="title">Programmer Analyst</div>
                    <div className="place">CDI College - 2004</div>
                </div>
            </Col>   
                     
        </>
    )
}

export default Education;