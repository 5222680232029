import React from "react";
import '../css/circles.css';

function BackgroundCircles() {
    return (
        <>
            <div className="circle spin">
            </div>
            <div className="circle-two spin">
            </div>
            <div className="circle-three spin">
            </div>
        </>
    )
}

export default BackgroundCircles;