import React from "react";
import { Col, Row } from "react-bootstrap";
import SimpleReactLightbox from "simple-react-lightbox";
import '../css/ui-panel-inner.css';

function InnerUiPanel({children}) {
    return (
        <Row>
            <SimpleReactLightbox>
                <Col xs={12} className="mb-2">
                    <div className="ui-panel-inner ">{ children } </div>
                </Col>
            </SimpleReactLightbox>
        </Row>

    )
}

export default InnerUiPanel;