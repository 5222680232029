import React from "react";
import { Col } from "react-bootstrap";
import '../css/ui-panel.css';
import '../css/header.css';
import InnerUiPanel from "./InnerUiPanel";
import '../css/experience.css';
import ExperienceDetail from "./experience-detail";

function Experience() {

    return (
        <>
            <Col className="p-2" sm={12}>
                <div className="ui-text-shadow header-text text-sm-begin text-md-end">Experience</div>
            </Col>
            <Col>
                <InnerUiPanel children={
                    <ExperienceDetail
                        year="2015 "
                        title="Chief Technology Officer"
                        business="West Island College"
                        location="Calgary, AB, Canada"
                        description="Head of IT department servicing over 1400 yearly end users. Managed a yearly budget of $550k.
                         Developed and implemented infrastructure, software and solutions for teachers and students."
                        />}/>
                <InnerUiPanel children={
                    <ExperienceDetail
                        year="2015"
                        title="Senior Technical Consultant"
                        business="Office Solutions Inc."
                        location="Calgary, AB, Canada"
                        description="Implemented a centralized and standardized IT documentation system between all OSI’s clients using
                        Microsoft OneNote, including a new standardized technical diagram process for all clients."
                        />}/>

                <InnerUiPanel children={
                    <ExperienceDetail
                        year="2012"
                        title="Senior Technical Consultant"
                        business="Payran Systems Inc."
                        location="Calgary, AB, Canada"
                        description="Involved in full life cycle of 21 small, medium, and enterprise sized business IT infrastructures. Designed and
                        implemented IT infrastructure, supported hundreds of end users."
                        />}/>
                <InnerUiPanel children={
                    <ExperienceDetail
                        year="2006"
                        title="Owner/Operator"
                        business="Designex.net"
                        location="High Prairie, AB, Canada"
                        description="Serviced hundreds of local customers and businesses serving High Prairie, Alberta. Developed software and infrastructure for business clients. The store had 2 employees and repaired computers, developed websites, and implemented business IT infrastructure."
                        />}/>
                <InnerUiPanel children={
                    <ExperienceDetail
                        year="2005"
                        title="Helpdesk Technical Support"
                        business="Dell Canada"
                        location="Edmonton, AB, Canada"
                        description="Assisted customers with software and hardware issues relating to their Dell computers.
                        Trained other employees in DellServ and Hardware Knowledge"
                        />}/>
            </Col>
        </>
    )
}

export default Experience;