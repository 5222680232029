import React from "react";
import '../css/ui-panel.css';
import '../css/header.css';
import {AiFillInstagram} from 'react-icons/ai';
import {ImLinkedin } from 'react-icons/im';
import { AiFillGithub } from 'react-icons/ai';

function Header() {

    return (
        <>
            <div className="mb-2 mt-2 text-center">
                <img 
                     alt="Me"
                     className="img-holder" 
                     height="180px" src="https://i.imgur.com/gAKzJdq.png" />
            </div>
            <div className="text-center ui-text-shadow my-name" >Mitchell Matula</div>
            
            <div className="text-center   ui-text-shadow">
                <a href="https://www.instagram.com/phennexion" className="social-icon" target="_blank">
                <AiFillInstagram ></AiFillInstagram> &nbsp; &nbsp;
                </a>
                <a href="https://www.linkedin.com/in/h6fv8o4h8t/" className="social-icon" target="_blank">
                <ImLinkedin className="social-icon"></ImLinkedin> &nbsp; &nbsp;
                </a>
                <a href="https://github.com/mitchellmatula" className="social-icon" target="_blank">
                <AiFillGithub className="social-icon"></AiFillGithub>
                </a>
                
            </div>
            <div className="text-center place mb-3 mt-2">
               it generalist &nbsp;&nbsp; designer &nbsp;&nbsp; developer
            </div>
        </>
    )
}

export default Header;