import React from "react";
import { Col } from "react-bootstrap";

function SkillDetails({skill, experience}){
    return (
        <>
            <Col xs={4} className="mt-1">
                <div className="place">{skill}</div>
                
            </Col>
        </>
    )
}

export default SkillDetails;