import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import '../css/portfolio-detail.css';
import { useLightbox } from 'simple-react-lightbox';

function PortfolioDetail({images, alt, title, description, technology}){
    const { openLightbox } = useLightbox()
    return (
        <>
            
                <SRLWrapper>
                    <div onClick={ () => openLightbox()} >
                        <div className="text-center p-2" >
                            <img 
                                className="mt-2 img-holder img-responsive" 
                                alt={alt} 
                                
                                src={images[0]} 
                            />
    
                        </div>
                        
                        <div className="hidden-images">
                            {images.map((image, i) => {
                                if(i > 0) {
                                        return(
                                            <img 
                                                className="mt-2 img-holder" 
                                                alt={alt} 
    
                                                src={image}
                                                key={i}
                                            />)
                                } else {
                                    return "<div></div>";
                                }
                            })}
                        </div>
    
                        <div className="title text-center mt-2 ">
                            {title}
                        </div>
                        <div className="place text-center p-3">
                        {description}
                        </div>
                        <div className="place text-center mb-3 " >
                            {technology.map((skill, i) => <span key={i}>{skill}&nbsp;&nbsp;</span>)}
                        
                        </div>
                            
                </div>
            </SRLWrapper>  
        </>
    )
}

export default PortfolioDetail;

