import React from "react";
import { Col, Row } from "react-bootstrap";
import '../css/ui-panel.css';
import '../css/header.css';
import SkillDetails from "./skill-details";

function Interests() {

    return (
        <>
            <Col className=" text-md-begin p-2 " sm={12} md={12}>
                <div className="ui-text-shadow header-text">Interests</div>
                <Row>
                    <SkillDetails skill="Adventure" experience="5 years" />
                    <SkillDetails skill="Travel" experience="5 years" />
                    <SkillDetails skill="Hiking" experience="1 week" />
                    <SkillDetails skill="Gaming" experience="17 years" />
                    <SkillDetails skill="Ninja Warrior" experience="10 years" />
                    <SkillDetails skill="Cooking" experience="10 years" />
                    <SkillDetails skill="Learning" experience="1 week" />
                    <SkillDetails skill="Woodworking" experience="1 week" />

                </Row>
            </Col>   
                     
        </>
    )
}

export default Interests;