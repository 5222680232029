import React from "react";
import { Col, Row } from "react-bootstrap";
import '../css/ui-panel.css';
import '../css/header.css';
import SkillDetails from "./skill-details";

function Skills() {

    return (
        <>
            <Col className=" text-md-begin p-2" sm={12} md={12}>
                <div className="ui-text-shadow header-text">Skills</div>
                <Row>
                    <SkillDetails skill="Angular" experience="5 years" />
                    <SkillDetails skill=".NET Core" experience="5 years" />
                    <SkillDetails skill="React" experience="1 week" />
                    <SkillDetails skill="HTML" experience="17 years" />
                    <SkillDetails skill="CSS" experience="10 years" />
                    <SkillDetails skill="JQuery" experience="1 week" />
                    <SkillDetails skill="C#" experience="10 years" />
                    <SkillDetails skill="UI/UX" experience="1 week" />
                    <SkillDetails skill="Integration" experience="1 week" />
                    <SkillDetails skill="JavaScript" experience="1 week" />
                    <SkillDetails skill="SQL" experience="1 week" />
                    <SkillDetails skill="Redis" experience="1 week" />


                </Row>
            </Col>   
                     
        </>
    )
}

export default Skills;