import React from "react";
import { Col, Row } from "react-bootstrap";
import '../css/ui-panel.css';
import '../css/header.css';
import InnerUiPanel from "./InnerUiPanel";
import '../css/experience.css';
import PortfolioDetail from "./portfolio-detail";
import designex from '../images/designex.jpg';
import phennexion from '../images/newlayout.jpg';
import immersion from '../images/immersion.gif';
import nanalysis from '../images/nanalysis_core.png';


function Portfolio() {

    return (
        <>
            <Col className="p-2" sm={12}>
                <div className="ui-text-shadow header-text text-sm-begin text-md-end">Portfolio</div>
            </Col>
            <Row>

                <Col sm={12} md={4}>
                    <Row>
                        <Col sm={12}>
                            <InnerUiPanel children={
                                <PortfolioDetail
                                    images={["https://i.imgur.com/695T4hR.png",
                                    'https://i.imgur.com/0AOHJUp.png',
                                    'https://i.imgur.com/7Rowrp4.png',
                                        'https://i.imgur.com/CEEQ4xd.png']} 
                                    alt="Campus Connect"
                                    title="Campus Connect"
                                    description=" Developed with West Island College to be a single portal experience for students to recieve
                                    communications and access their school cloud services from their mobile app. 
                                    Developed fee management, demographic, scheduling, and reporting modules. 600 daily active users."
                                    technology={['.NET 5', 'Angular', 'Redis',  'SQL', 'Hangfire']} 
                                />}
                            />
                            <InnerUiPanel children={
                                        <PortfolioDetail
                                            images={["https://i.ibb.co/P15TgV8/fighting-game-concept.png"]}
                                            alt="Concept Fighting Game"
                                            title="Concept Fighting Game"
                                            description="Prototyped a fighting game for mobile use using real life physics and touch input."
                                            technology={['BYOND', 'Javascript', 'DM']} 
                                        />}
                                    />
                        </Col>
                    </Row>
                </Col>

                <Col sm={12} md={4}>
                    <Row>
                        <Col sm={12} className="p-md-0">
                            <InnerUiPanel children={
                                    <PortfolioDetail
                                        images={[nanalysis]}
                                        alt="Nanalysis Core"
                                        title="Nanalysis Core"
                                        description="Developed for Nanalysis to manage their Active Directory via web interface that followed strict business logic."
                                        technology={['.NET 5', 'Angular', 'Active Directory',  'SQL']} 
                                    />}/>
                            <InnerUiPanel children={
                                <PortfolioDetail
                                    images={[immersion]}
                                    alt="Immersion"
                                    title="Immersion"
                                    description="Website I had designed and developed back in 2003 for a Photoshop Contest"
                                    technology={['Squarespace', 'HTML', 'CSS']} 
                                />}/>
                             <InnerUiPanel children={
                                <PortfolioDetail
                                        images={["https://i.imgur.com/26s7tIS.png"]}
                                        alt="COR.FIT Website"
                                        title="COR.FIT"
                                        description="Website developed for local fitness gym"
                                        technology={['Squarespace', 'Javascript', 'CSS']} 
                                />}/>
                        </Col>
                    </Row>
                </Col>



                <Col sm={12} md={4}   >

                        <Row>
                            <Col sm={12}>
                                <InnerUiPanel children={
                                    <PortfolioDetail
                                        images={[designex]}
                                        alt="Designex.net"
                                        title="Designex.net"
                                        description="Company website I designed in 2007"
                                        technology={['Javascript', 'HTML', 'CSS']} 
                                    />}/>
                                <InnerUiPanel children={
                                    <PortfolioDetail
                                        images={[phennexion]}
                                        alt="Personal Website"
                                        title="Personal Website"
                                        description="Personal website I designed back in 2006"
                                        technology={['Javascript', 'HTML', 'CSS']} 
                                />}/>
                            
                            </Col>
                        </Row>
                </Col>



            </Row>
        </>
    )
}

export default Portfolio;