import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

function ExperienceDetail({year, title, business, location, description}){
    const [toggle, setToggle] = useState(false);
    return (
        <>
        <Row>
            <Col sm={12} 
                onClick={() => {
                    return setToggle(!toggle);
                }}>

                <Row className="p-3">
                    <Col sm={1} className="text-md-end text-sm-start my-auto year">
                    {year}
                    </Col>
                    <Col sm={10} >
                        <div className="title">{title}</div> 
                        <div className="place">{business} - {location}</div>
                    </Col>
                </Row>
                {toggle && 
                    <Row>
                        <Col xs={0} lg={1}>
                        </Col>
                        <Col xs={12} md={12} lg={10}>
                            <div className="p-2 place">{description}</div>
                        </Col>
                    </Row>
                }
            </Col>  
        </Row>
        </>
    )
}

export default ExperienceDetail;